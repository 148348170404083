import { DetailedHTMLProps, InputHTMLAttributes, PropsWithChildren, forwardRef } from 'react';

import { AriaCheckboxProps } from '@react-types/checkbox';

import { IconDone } from '@agentero/icons';
import { VisuallyHidden, styled } from '@agentero/styles/jsx';
import { StyledVariantProps } from '@agentero/styles/types';

import { Label } from '../shared/Label';

const Checkbox = styled('div', {
	base: {
		height: 16,
		width: 16,
		marginBlockStart: '2',
		alignItems: 'center',
		borderColor: 'neutral.60',
		borderWidth: '1px',
		color: 'neutral.10',
		cursor: 'pointer',
		display: 'flex',
		borderRadius: 'xs',
		justifyContent: 'center',
		transitionDuration: '.2s',
		transitionProperty: 'border-color, background',
		transitionTimingFunction: 'ease-in-out',

		_checked: {
			background: 'neutral.10',
			borderColor: 'neutral.10',
			_disabled: {
				backgroundColor: 'neutral.80',
				borderColor: 'neutral.80'
			}
		},
		_indeterminate: {
			background: 'neutral.10',
			borderColor: 'neutral.10',

			_disabled: {
				backgroundColor: 'neutral.80',
				borderColor: 'neutral.80'
			}
		},
		_disabled: {
			cursor: 'not-allowed',
			borderColor: 'neutral.90'
		},
		_invalid: {
			borderColor: 'red',
			_checked: {
				borderColor: 'red',
				backgroundColor: 'red',

				_disabled: {
					backgroundColor: 'red.80',
					borderColor: 'red.80'
				}
			},
			_indeterminate: {
				borderColor: 'red',
				backgroundColor: 'red',

				_disabled: {
					backgroundColor: 'red.80',
					borderColor: 'red.80'
				}
			},
			_disabled: {
				borderColor: 'red.80'
			}
		},
		'& svg path': {
			fill: 'white',
			stroke: 'white'
		}
	}
});

const CheckboxLabel = styled(Label, {
	base: {
		alignItems: 'start',
		gap: 8,
		textStyle: 'body.small',
		display: 'flex',
		userSelect: 'none'
	}
});

export type CheckboxVariants = StyledVariantProps<typeof Checkbox>;
export type CheckboxComponentProps = AriaCheckboxProps &
	CheckboxVariants & {
		inputProps: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
	};

export const CheckboxComponent = forwardRef<
	HTMLInputElement,
	PropsWithChildren<CheckboxComponentProps>
>((props, ref) => {
	const { inputProps, isDisabled, isInvalid, isIndeterminate, isSelected, children } = props;

	return (
		<CheckboxLabel data-disabled={isDisabled || undefined}>
			<VisuallyHidden>
				<input {...inputProps} ref={ref} />
			</VisuallyHidden>
			<Checkbox
				data-checked={isSelected || undefined}
				data-indeterminate={isIndeterminate || undefined}
				data-disabled={isDisabled || undefined}
				data-invalid={isInvalid || undefined}
				aria-hidden="true">
				{isIndeterminate ? <MinusIcon /> : <IconDone />}
			</Checkbox>
			{children && <span>{children}</span>}
		</CheckboxLabel>
	);
});

const MinusIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		stroke="currentColor"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
		className="lucide lucide-minus">
		<path d="M5 12h14" />
	</svg>
);
